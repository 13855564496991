import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './interceptions/axios';
// import { VueReCaptcha } from 'vue-recaptcha-v3';

const app = createApp(App);

// app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_PUBKEY });
app.use(router);
app.mount('#app');
