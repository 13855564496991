<template>
    <ModalWindow @close="$emit('close')" :title="'Member'" :width="'400px'">
        <div>
            <div class='modal-section'>
                <span class="modal-section-title">Name</span>
                <input :value="member.first_name + ' ' + member.last_name" class="cs-input small" disabled>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Email</span>
                <input :value="member.email" class="cs-input small" disabled>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Access Level</span>
                <select v-model="edited.access_level" class="cs-select small non-empty">
                    <option value="3">Admin</option>
                    <option value="2">Manager</option>
                    <option value="1">User</option>
                </select>
            </div>
            <div class='modal-section'>
                <span class="modal-section-title">Blocked</span>
                <select v-model="edited.is_disabled" class="cs-select small non-empty">
                    <option :value="true">True</option>
                    <option :value="false">False</option>
                </select>
            </div>
            <div class="actions">
                <button :disabled="isPosting || !changed" @click="updateMember" class="cs-button small next">Update</button>
                <button :disabled="isPosting" @click="deleteMember" class="cs-button small danger">Delete</button>
            </div>
        </div>
    </ModalWindow>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import ModalWindow from '@/components/common/Modal.vue'

export default {
    name: 'EditMemberModal',
    components: { ModalWindow },
    emits: ['close', 'refresh'],
    props: {
        member: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            accessLevels: {
                3: 'Admin',
                2: 'Manager',
                1: 'User',
            },
            isPosting: false,
            edited: JSON.parse(JSON.stringify(this.member)),
        }
    },
    computed: {
        changed() {
            return !_.isEqual(this.member, this.edited)
        }
    },
    methods: {
        async updateMember() {
            this.isPosting = true;
            try {
                const url = `organization/members/${this.member.id}`
                await axios.put(url, this.edited)
                this.$root.displaySuccess("User updated")
                this.$emit('refresh')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
            this.isPosting = false;
        },
        async deleteMember() {
            this.isPosting = true;
            try {
                const url = `organization/members/${this.member.id}`
                await axios.delete(url)
                this.$root.displaySuccess("User deleted")
                this.$emit('refresh')
                this.$emit('close')
            } catch (e) {
                this.$root.displayError(e.response.data.detail)
            }
            this.isPosting = false;
        }
    },
}
</script>
<style scoped>
.update-btn {
    margin-left: 10px;
}

.actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
</style>