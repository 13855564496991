<template>
    <div id="strategy-chart"></div>
</template>
<script>
import { createChart } from 'lightweight-charts';
import { calculateMinMove, calculatePrecision } from '@/utils/funcs';

export default {
    name: "ChartComponent",
    props: {
        startPrice: {
            type: Number,
            default: 1,
        },
        strategyCandles: {
            type: Object,
            required: true,
        },
        currentCandleInfo: {
            type: Object,
            required: false,
        },
        timeframe: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            chartCanvas: null,
        }
    },
    mounted() {
        this.chartCanvas = document.getElementById('strategy-chart')
        this.drawChart();
    },
    methods: {
        getMarkers() {
            if (!this.currentCandleInfo.events) return [];
            return this.currentCandleInfo.events.map(event => {
                return {
                    time: Math.floor(Date.parse(event.created_at + 'Z') / 1000),
                    position: 'aboveBar',
                    color: '#000000',
                    shape: 'arrowDown',
                    text: event.event + ` (${event.created_at.replace("T", " ").slice(11, -7)})`
                };
            }).reverse();
        },
        getCandles() {
            if (!this.strategyCandles) return [];
            return this.strategyCandles.map(candle => ({
                time: Math.floor(Date.parse(candle.open_time + 'Z') / 1000),
                open: candle.open * this.startPrice,
                high: candle.high * this.startPrice,
                low: candle.low * this.startPrice,
                close: candle.close * this.startPrice,
            }));
        },
        getPricePath() {
            if (!this.currentCandleInfo.price_path) return [];
            return this.currentCandleInfo.price_path.map(price => ({
                time: Math.floor(Date.parse(price.created_at + 'Z') / 1000),
                value: price.price
            })).reverse();
        },
        drawChart() {
            if (!this.startPrice) {
                this.$root.displayError('Unable to draw chart: no starting price available')
                return
            }
            this.chartCanvas.innerHTML = '';
            const chart = createChart(this.chartCanvas, {
                autoSize: true,
                timeScale: { timeVisible: true, fitContent: true },
            });

            const candlesData = this.getCandles()
            const candlestickSeries = chart.addCandlestickSeries({
                priceFormat: {
                    minMove: calculateMinMove(candlesData),
                    precision: calculatePrecision(candlesData)
            }
            });
            candlestickSeries.setData(candlesData);

            if (this.currentCandleInfo) {
                const candleMarkersData = this.getMarkers()
                const candlePricePathData = this.getPricePath()

                const candlePriceChart = chart.addLineSeries({
                    color: '#2962FF', lineWidth: 2, priceFormat: {
                        minMove: calculateMinMove(candlePricePathData),
                        precision: calculatePrecision(candlePricePathData)
                    }
                })
                candlePriceChart.setData(candlePricePathData)
                candlePriceChart.setMarkers(candleMarkersData);
            }
        }
    },
}
</script>
<style scoped>
#strategy-chart {
    width: 100%;
    height: 100%;
}
</style>