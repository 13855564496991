<template>
    <div class="cs">
        <div class="cs-header">
            Log in
        </div>
        <div class="cs-content">
            <div class="cs-tools">
                <input class="cs-input" v-model="username" :class="{ 'non-empty': username.length > 0 }"
                    placeholder="E-Mail">
            </div>
            <div class="cs-tools">
                <input class="cs-input" type="password" v-model="password" :class="{ 'non-empty': password.length > 0 }"
                    placeholder="Password">
                <span @click="$emit('passrec')">Forget password?</span>
            </div>
            <div class="cs-tools">
                <button class="cs-button next" :disabled="!(password && username)" @click="prelogin">Next</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "PreLogin",
    emits: ['upd-context', 'twofa', 'passrec'],
    props: {
        context: {
            type: Object
        }
    },
    data() {
        return {
            username: this.context.username,
            password: "",
        }
    },
    methods: {
        async prelogin() {
            // const recaptchaToken = await this.$root.captchaToken('prelogin');
            const loginForm = new FormData();
            loginForm.append("username", this.username);
            loginForm.append("password", this.password);
            loginForm.append("recaptcha", null);

            try {
                const {data} = await axios.post('auth', loginForm, { withCredentials: true });
                if (data.access_token) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
                    this.$router.push("/home");
                    return
                }
                this.$emit('upd-context', { username: this.username, twofaId: data.twofa_id, resendWait: data.resend_wait })
                this.$emit('twofa')
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
    },
}
</script>
<style scoped>
.cs-content > :first-child{
    margin-bottom: 10px;
}
.cs-tools {
    display: flex;
    flex-direction: column;
}

.cs-tools>input {
    margin-left: -2px;
    width: 300px;
}
.cs-tools > span{
    margin-top: 7px;
    margin-left: 18px;
    font-size: 12px;
    cursor: pointer;
}
.cs-tools > span:hover{
    text-decoration: underline;
}

.cs-content> :last-child {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}
</style>