<template>
    <HeaderComponent />
    <LoaderCircle v-if='!isLoaded' />
    <div class="organization-settings-page" v-else>
        <div class="sidebar">
            <SidebarComponent :menu="['General', 'Subscription', 'Telegram']" :initialLocation="currentTab"
                @switch="(newLocation) => { currentTab = newLocation }" />
        </div>
        <div class="page-content-wrapper">
            <div class="page-content">
                <GeneralSegment v-if="currentTab === 'General'" :organization="organization" />
                <Subscription v-if="currentTab === 'Subscription'" :organization="organization" />
                <TelegramSection v-if="currentTab === 'Telegram'" :organization="organization" />
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import HeaderComponent from "@/components/Header.vue"
import SidebarComponent from "./SidebarComponent.vue";
import GeneralSegment from "./General/GeneralSegment.vue";
import LoaderCircle from '@/components/common/Loader.vue';
import Subscription from "./Subscription/SubscriptionSegment.vue";
import TelegramSection from "./Telegram/TelegramSection.vue";

export default {
    name: "NewOrganizationPage",
    components: { HeaderComponent, SidebarComponent, GeneralSegment, LoaderCircle, Subscription, TelegramSection },
    data() {
        return {
            currentTab: 'General',

            isLoaded: false,
            organization: null,
        }
    },
    async created() {
        await this.fetchOrganization();
        this.isLoaded = true;
    },
    methods: {
        async fetchOrganization() {
            try {
                const url = '/organization'
                const { data } = await axios.get(url)
                this.organization = data
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        }
    }
}
</script>
<style scoped>
.organization-settings-page {
    margin: 0 5%;
    display: flex;
}

.sidebar {
    width: 200px;
}

.page-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page-content {
    width: 60%;
    font-family: 'Oxygen';
}
</style>