<template>
    <div class="cs">
        <div class="cs-header">
            Recover password
        </div>
        <div v-if="!wasConfirmed" class="cs-content first">
            <div class="cs-tools">
                <input class="cs-input" v-model="username" placeholder="E-Mail"
                    :class="{ 'non-empty': username.length > 0 }" :disabled="wasSend">
                <div class="resend-container">
                    <span v-if="resendWait > 0">{{ resendWait }}</span>
                    <button v-if="!wasSend" class="cs-button" :disabled="resendWait > 0" @click="start">Send</button>
                    <button v-else class="cs-button" :disabled="resendWait > 0" @click="resend">Resend</button>
                </div>
            </div>
            <div class="cs-tools" v-if="wasSend">
                <input class="cs-input" v-model="code" :class="{ 'non-empty': code.length > 0 }"
                    placeholder="Put your token here">
                <button class="cs-button next" :disabled="!code" @click="verify">Verify</button>
            </div>
        </div>
        <div v-else class="cs-content">
            <div class="cs-tools">
                <input class="cs-input" type="password" v-model="newPassword" placeholder="New password"
                    :class="{ 'non-empty': username.length > 0 }">
                <button class="cs-button next" :disabled="!isPasswordStrong" @click="finish">Change</button>
            </div>
            <div class="cs-tools">
                <div class="password-rules">
                    <span :class="{ passed: checkLenght }">At least 12 symbols</span>
                    <span :class="{ passed: checkDigits }">At least 2 digits</span>
                    <span :class="{ passed: checkUpper }">At least 2 uppercase letters</span>
                    <span :class="{ passed: checkPunct }">At least 1 punctuation char</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "PasswordRecovery",
    emits: ['upd-context', 'prelogin'],
    data() {
        return {
            wasSend: false,
            wasConfirmed: false,
            username: "",
            newPassword: "",
            code: "",
            recoveryId: null,
            resendWait: null,
            timer: null,
        }
    },
    async mounted() {
        this.startTimer();
    },
    beforeUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    computed: {
        checkLenght() {
            return this.newPassword.length >= 12
        },
        checkDigits() {
            return (this.newPassword.match(/\d/g) || []).length >= 2
        },
        checkUpper() {
            return (this.newPassword.match(/[A-Z]/g) || []).length >= 2
        },
        checkPunct() {
            return (this.newPassword.match(/[!@#$%^&*(),.?":{}|<>]/g) || []).length >= 1
        },
        isPasswordStrong() {
            return this.checkLenght && this.checkDigits && this.checkPunct && this.checkUpper
        }
    },
    methods: {
        async start() {
            try {
                const url = `auth/recover-password/start`;
                // const recaptchaToken = await this.$root.captchaToken('prelogin');
                const { data } = await axios.post(url, { username: this.username, recaptcha: null })
                this.wasSend = true;
                this.resendWait = data.resend_wait;
                this.recoveryId = data.recovery_id;
                this.startTimer();
            } catch (error) {
                if (error.response.status === 404) {
                    this.$root.displayError('User not found')    
                } else {
                    this.$root.displayError(error.response.data.detail)
                }
            }
        },
        async resend() {
            try {
                const url = `auth/recover-password/resend`;
                const { data } = await axios.post(url, { old_recovery_id: this.recoveryId })
                this.resendWait = data.resend_wait;
                this.recoveryId = data.recovery_id;
                this.startTimer();
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async verify() {
            try {
                const url = `auth/recover-password/verify`;
                await axios.post(url, { recovery_id: this.recoveryId, code: this.code })
                this.wasConfirmed = true;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async finish() {
            try {
                const url = `auth/recover-password/set`;
                await axios.post(url, { recovery_id: this.recoveryId, new_password: this.newPassword })
                this.$emit('upd-context', { username: this.username })
                this.$emit('prelogin')
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        startTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.resendWait > 0) {
                    this.resendWait--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        }
    },
}
</script>
<style scoped>
.cs-tools {
    display: flex;
    justify-content: space-between;
}

.cs-tools>input {
    width: 300px;
    margin-left: -2px;
}

.resend-container {
    display: flex;
    gap: 20px;
    align-items: center
}

.resend-container>span {
    color: #a0a0a0;
}

.cs-content> :last-child {
    display: flex;
}
.cs-content.first{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.cs-tools.finish {
    flex-direction: row-reverse;
}

.password-rules {
    margin-top: 10px;
    margin-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
}

.password-rules>.passed {
    color: #05C023;
}
</style>