<template>
    <div class="chart-preview-container">
        <div id="chart-preview"></div>
    </div>
</template>
<script>
import { createChart } from 'lightweight-charts';
import { calculateMinMove, calculatePrecision } from '@/utils/funcs';

export default {
    name: "ChartPreview",
    props: {
        data: {
            type: Object,
            required: true
        },
        timeframe: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.generate()
    },
    computed: {
        ohlcWithTime() {
            const frameDuration = this.timeframeInSeconds(this.timeframe);
            const currentTime = Math.floor(new Date().getTime() / 1000);
            const ohlc = [...this.data];
            return ohlc.map((item, index) => {
                const time = currentTime + index * frameDuration;
                return { time, ...item };
            });
        }
    },
    watch: {
        ohlcWithTime() {
            this.generate()
        }
    },
    methods: {
        timeframeInSeconds(value) {
            const unit = value[value.length - 1];
            const amount = parseInt(value.slice(0, -1), 10);
            switch (unit) {
                case 'm':
                    return amount * 60;
                case 'h':
                    return amount * 60 * 60;
                default:
                    return 0;
            }
        },
        generate() {
            const generatedChart = document.getElementById('chart-preview');
            generatedChart.innerHTML = '';
            generatedChart.style.display = 'flex';
            const chart = createChart(generatedChart, {
                autoSize: true,
                timeScale: { timeVisible: true, fitContent: true, },
            });
            const candlestickSeries = chart.addCandlestickSeries({
                priceFormat: {
                    minMove: calculateMinMove(this.ohlcWithTime),
                    precision: calculatePrecision(this.ohlcWithTime),
                },
            });
            candlestickSeries.setData(this.ohlcWithTime);
        }
    }
}
</script>
<style scoped>
.chart-preview-container {
    display: flex;
    justify-content: center;
}

#chart-preview {
    width: 90%;
    height: 500px;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    padding: 20px;
}
</style>