<template>
    <ModalWindow @close="$emit('close')" :title="'New subcription'" :width="'auto'">
        <div v-if="!makingPayment">
            <div class="duration-selector">
                <span>Select duration</span>
                <select class="cs-select small non-empty" v-model="selectedData.duration">
                    <option value='Month'>Month</option>
                    <option value='HalfYear'>6 months</option>
                    <option value='Year'>12 months</option>
                </select>
            </div>
            <div class="subscription-selector" v-if="selectedData.duration">
                <div class="subscription-type" v-for="type in subscriptionTypes" :key="type.id"
                    :class="{ chosen: type.id === selectedData.subscriptionTypeId }">
                    <div class="type-name">{{ type.name }}</div>
                    <div class="type-info">
                        <div>
                            <span>Max pairs amount</span>
                            <span>{{ type.max_pairs }}</span>
                        </div>
                        <div>
                            <span>Max users amount</span>
                            <span>{{ type.max_users }}</span>
                        </div>
                    </div>
                    <div class="type-footer">
                        <span class="type-price"><b>${{ type.usd_price }}</b></span>
                        <button class="cs-button next small" @click="selectedData.subscriptionTypeId = type.id"
                            v-if="selectedData.subscriptionTypeId !== type.id">Choose</button>
                    </div>
                </div>
            </div>
            <div class="payment-selector" v-if="selectedData.subscriptionTypeId">
                <div><b>Choose payment method</b></div>
                <div>
                    <select class="cs-select non-empty" v-model="selectedData.networkId">
                        <option v-for="network in paymentNetworks" :key="network.id" :value="network.id">{{
                            network.title }}
                        </option>
                    </select>
                    <select class="cs-select non-empty" v-model="selectedData.tokenId">
                        <option v-for="token in paymentTokens" :key="token.id" :value="token.id">{{ token.symbol }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="transfer-btn-container">
                <button class="cs-button next" @click="startPayment();" v-if="selectedData.tokenId">
                    Transfer
                </button>
            </div>
        </div>
        <div class="payment-container" v-else>
            <LoadingCircle v-if="!paymentData" />
            <div v-else class="payment-info">
                <button class="cs-button small" @click="makingPayment = false; paymentData = null">
                    Back
                </button>
                <div class="transfer-info">
                    <div>
                        Please transfer
                        <span class="payment-accent">
                            {{ paymentData.amount }} {{ paymentData.token_symbol }}
                            <a v-bind:href="paymentData.network_explorer + '/address/' + paymentData.token_contract" target="_blank">
                                <img src="/icons/link.svg" class="token-link">
                            </a>
                        </span>
                        
                    </div>
                    <div>
                        to
                        <span class="payment-accent">{{ paymentData.payment_address }}</span>
                    </div>
                    <div>
                        via
                        <span class="payment-accent">{{ paymentData.network_title }}</span>
                    </div>
                </div>
                <div class="session-expires">
                    This session is valid until
                    {{ paymentData.valid_until.slice(0, 10) }}
                    {{ paymentData.valid_until.slice(11, 19) }} UTC.
                </div>
                <button :disabled="isChecking" class="cs-button next check-btn" @click="checkTransfer">I've transfered tokens</button>
            </div>
        </div>
    </ModalWindow>
</template>
<script>
import ModalWindow from '@/components/common/Modal.vue'
import LoadingCircle from "@/components/common/Loader.vue"
import axios from 'axios';

export default {
    components: { ModalWindow, LoadingCircle },
    emits: ['close', 'refresh'],
    data() {
        return {
            isLoaded: false,
            isChecking: false,

            subscriptionTypes: [],
            paymentNetworks: [],
            paymentTokens: [],

            selectedData: {
                duration: null,
                subscriptionTypeId: null,
                networkId: null,
                tokenId: null,
            },
            makingPayment: false,
            paymentData: null,
        }
    },
    async mounted() {
        this.selectedData.duration = 'Month'
        this.isLoaded = true;
    },
    watch: {
        'selectedData.duration'() {
            this.selectedData.subscriptionTypeId = null;
            this.fetchSubscriptionTypes()
        },
        'selectedData.subscriptionTypeId'(newVal) {
            this.selectedData.networkId = null;
            if (newVal) this.fetchPaymentNetworks()
        },
        'selectedData.networkId'(newVal) {
            this.selectedData.tokenId = null;
            if (newVal) this.fetchSymbols()
        },
    },
    methods: {
        async fetchSubscriptionTypes() {
            try {
                const url = 'organization/subscription/available-types'
                const { data } = await axios.get(url, {
                    params: {
                        duration: this.selectedData.duration,
                    }
                })
                this.subscriptionTypes = data
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async fetchPaymentNetworks() {
            try {
                const url = 'organization/subscription/payment/networks'
                const { data } = await axios.get(url)
                this.paymentNetworks = data;
                this.selectedData.networkId = this.paymentNetworks[0].id
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async fetchSymbols() {
            try {
                const url = 'organization/subscription/payment/tokens'
                const { data } = await axios.get(url, {
                    params: {
                        network_id: this.selectedData.networkId
                    }
                })
                this.paymentTokens = data;
                this.selectedData.tokenId = this.paymentTokens[0].id
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async startPayment() {
            this.makingPayment = true;
            try {
                const url = 'organization/subscription/payment/start'
                const { data } = await axios.post(url, {
                    subscription_type_id: this.selectedData.subscriptionTypeId,
                    token_id: this.selectedData.tokenId,
                })
                this.paymentData = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
        async checkTransfer() {
            this.isChecking = true;
            try {
                const url = 'organization/subscription/payment/check'
                const { data } = await axios.post(url, {
                    payment_session_id: this.paymentData.payment_session_id,
                })
                if (data.success) {
                    this.$root.displaySuccess('Payment processed successfully');
                    this.$emit('refresh');
                    this.$emit('close');
                    return
                }
                this.$root.displayError(`We've received a payment, but the amount is not full. Remaining part: ${data.remaining} ${this.paymentData.token_symbol}`);
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
            this.isChecking = false;
        },
    }
}
</script>
<style scoped>
.duration-selector {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.subscription-selector {
    display: flex;
    gap: 10px;
}

.subscription-type {
    padding: 20px;
    display: inline-block;
    border-radius: 15px;
    border: 1px solid black;
    width: 300px
}

.subscription-type.chosen {
    background-color: aliceblue
}

.type-name {
    font-size: 32px;
    margin-bottom: 20px;
}

.type-info {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.type-info>div {
    display: flex;
    justify-content: space-between;
}

.type-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}

.type-footer>span {
    font-size: 24px;
}

.payment-selector {
    margin-top: 40px;
}

.payment-selector> :first-child {
    margin-left: 5px;
    margin-bottom: 10px;
}

.payment-selector>div {
    display: flex;
    gap: 5px;
    align-items: center;
}

.transfer-info {
    margin-top: 30px;
    margin-left: 5px;
}

.transfer-info>div {
    line-height: 40px;
}

.payment-accent {
    padding: 5px 10px;
    background-color: #eee;
    border-radius: 10px;
    font-weight: 700;
}

.transfer-btn-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.check-btn{
    margin-top: 10px;
    width: 100%;
}
.token-link{
    width: 12px;
    height: auto;
}
.session-expires{
    margin-top: 20px;
    font-style: italic;
    font-size: 14px;
}

</style>