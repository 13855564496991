<template>
    <div class="items-list">
        <button v-for="item in menu" :key="item" @click="switchLocation(item)" :class="{ active: location === item }">
            {{ item }}
        </button>
    </div>
</template>
<script>
export default {
    name: "SidebarComponent",
    emits: ['switch'],
    props: {
        menu: {
            type: Object,
            required: true,
        },
        initialLocation: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            location: this.initialLocation,
        }
    },
    methods: {
        switchLocation(location) {
            if (this.location === location) return;
            this.location = location;
            this.$emit('switch', location)
        },
    },
}
</script>
<style scoped>
.items-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.items-list>button {
    font-family: 'Oxygen';
    font-weight: 700;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 15px;
    width: 150px;
    text-align: left;
}

.items-list>button:hover,
.items-list>button.active {
    background-color: #EEEEEE;
}
</style>