<template>
    <div class="cs">
        <div class="cs-header">
            Introduce Yourself
        </div>
        <div class="cs-content">
            <div class="cs-tools">
                <input class="cs-input" v-model="firstName" :class="{ 'non-empty': firstName.length > 0 }"
                    placeholder="First name">
                <input class="cs-input" v-model="lastName" :class="{ 'non-empty': lastName.length > 0 }"
                    placeholder="Last name">
            </div>
            <div class="cs-tools">
                <input class="cs-input" v-model="email" :class="{ 'non-empty': email.length > 0 }" placeholder="Email">
            </div>
            <div class="cs-tools">
                <input class="cs-input" v-model="orgName" :class="{ 'non-empty': orgName.length > 0 }"
                    placeholder="Organization name">
            </div>
            <div class="cs-tools">
                <select class="cs-select" v-model="country" :class="{ 'non-empty': country !== 'default' }">
                    <option :value="'default'">— select —</option>
                    <option v-for="(value, key) in countries" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
            <div class="cs-tools">
                <button class="cs-button next" :disabled="!isCompleted" @click="save">Next</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { countries } from '@/utils/data';

export default {
    name: "IntroduceYourself",
    props: {
        context: {
            type: Object
        }
    },
    emits: ['updContext', 'back', 'next'],
    data() {
        return {
            firstName: this.context.firstName ? this.context.firstName : "",
            lastName: this.context.lastName ? this.context.lastName : "",
            email: this.context.email ? this.context.email : "",
            orgName: this.context.orgName ? this.context.orgName : "",
            country: this.context.country ? this.context.country : "default",

            countries,
        }
    },
    computed: {
        isCompleted() {
            if (this.firstName.length === 0) return false;
            if (this.lastName.length === 0) return false;
            if (this.email.length === 0) return false;
            if (this.orgName.length === 0) return false;
            if (this.country === 'default') return false;
            return true;
        }
    },
    methods: {
        async save() {
            try {
                const url = `registration/save`;
                const { status } = await axios.post(url, {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                    country: this.country,
                    org_name: this.orgName,
                    regform_id: this.context.regformId
                })
                if (status === 200) {
                    this.$emit('updContext', {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        country: this.country,
                        orgName: this.orgName,
                    })
                    this.$emit('next')
                }
            } catch (error) {
                if (error.response.status === 403) {
                    this.$root.displayError("This email is alread taken")
                    return
                }
                this.$root.displayError(error.response.data.detail)
            }
        },
    },
}
</script>
<style scoped>
.cs-tools {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cs-tools>input,
.cs-tools>select {
    width: 270px;
    margin-left: -2px;
}

.cs-content> :last-child {
    display: flex;
    flex-direction: row-reverse;
    ;
}
</style>