<template>
    <div class="cs-list" v-if="isLoaded">
        <div class="cs">
            <div class="cs-header">
                Current Subscription
            </div>
            <div class="cs-content">
                <span class='info' v-if="subscription">
                    <div>
                        <span>
                            Subscription type
                        </span>
                        <span>
                            {{ subscription.name }}
                        </span>
                    </div>
                    <div>
                        <span>
                            Ends on
                        </span>
                        <span>
                            {{ subscription.end_date }}
                        </span>
                    </div>
                </span>
                <span v-else>
                    You do not have active subscription right now.
                </span>
            </div>
            <div class="cs-footer">
                <button class="cs-button small next" @click="isBuying = true;" v-if="subscription">Extend</button>
                <button class="cs-button small next" @click="isBuying = true;" v-else>Buy</button>
            </div>
            <CreateSubscriptionModal v-if="isBuying" :outsideClose="false" @close="isBuying = false;"
                @refresh="fetchOrganizationSubscription" />
        </div>

    </div>
</template>
<script>
import axios from 'axios';
import CreateSubscriptionModal from './CreateSubscriptionModal.vue';

export default {
    props: {
        organization: {
            type: Object,
            required: true,
        }
    },
    components: { CreateSubscriptionModal },
    data() {
        return {
            isLoaded: false,
            subscription: null,
            isBuying: false,
        }
    },
    async created() {
        await this.fetchOrganizationSubscription()
        this.isLoaded = true;
    },
    methods: {
        async fetchOrganizationSubscription() {
            try {
                const url = 'organization/subscription'
                const { data } = await axios.get(url)
                this.subscription = data;
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
    }
}
</script>
<style scoped>
.info>div {
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
</style>