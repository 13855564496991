<template>
    <div class="cs-list">
        <div class="cs">
            <div class="cs-header">
                Title
            </div>
            <div class="cs-content">
                <span class="organization-title">{{ organization.title }}</span>
            </div>
        </div>
        <div class="cs">
            <div class="cs-header">
                Members
            </div>
            <div class="cs-content">
                <div class="members-list">
                    <div class="column-headers">
                        <span class="column-header column-username">Name</span>
                        <span class="column-header column-email">Email</span>
                        <span class="column-header column-level">Level</span>
                        <span class="column-header column-enabled">Blocked</span>
                    </div>
                    <span class="member-row" v-for="member in members" :key="member.id"
                        @click="editingMember = member;">
                        <span class="column-username column-value">{{ member.first_name }} {{ member.last_name }}</span>
                        <span class="column-email column-value">{{ member.email }}</span>
                        <span class="column-level column-value">{{ accessLevels[member.access_level] }}</span>
                        <span class="column-enabled column-value">{{ member.is_disabled ? "True" : "False" }}</span>
                    </span>
                </div>
            </div>
            <div class="cs-footer">
                <button class="cs-button small" @click="isCreaingNewMember = true">Add member</button>
            </div>
            <CreateMemberModal v-if="isCreaingNewMember" @close="isCreaingNewMember = false" @refresh="fetchMembers" />
            <EditMemberModal :member="editingMember" v-if="editingMember" @close="editingMember = null;"
                @refresh="fetchMembers" />
        </div>
    </div>

</template>
<script>
import axios from 'axios';
import CreateMemberModal from './Members/CreateModal.vue'
import EditMemberModal from './Members/EditModal.vue'

export default {
    name: "GeneralSegment",
    components: { CreateMemberModal, EditMemberModal },
    props: {
        organization: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            members: [],
            isCreaingNewMember: false,
            editingMember: null,
            accessLevels: {
                3: 'Admin',
                2: 'Manager',
                1: 'User',
            }
        }
    },
    async created() {
        await this.fetchMembers()
    },
    methods: {
        async fetchMembers() {
            try {
                const { data } = await axios.get('/organization/members');
                this.members = data
            } catch (error) {
                this.$root.displayError(error.response.data.detail)
            }
        },
    }
}
</script>
<style scoped>
.cs-list {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.cs {
    width: auto;
}

.organization-title {
    font-size: 24px;
}

.member-row {
    display: flex;
    margin-bottom: 5px;
}

.member-row:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.members-list {
    max-height: 200px;
    overflow-y: scroll;
}

.members-list>div>span {
    display: inline-block;
}

.column-headers {
    margin-bottom: 5px;
}

.column-header {
    font-size: 12px !important;
    font-weight: 700;
}

.column-value {
    font-size: 14px;
}

.column-username {
    width: 40%;
}

.column-email {
    width: 35%;
}

.column-level {
    width: 17%;
}

.column-enabled {
    width: 5%;
}
</style>