<template lang="">
    <div class="cs-header">Telegram</div>
    <div class="cs-content">
        <span v-if="organization.tg_channel">Сonnected!</span>
        <span v-else>
            <p>
                Add our
                <a target="_blank" href="https://t.me/bitfusion_bot?start=start">bot</a>
                to your channel, call
                <b>/link_organization</b>.
                Then put received token in lower form
            </p>
        <div class="cs-footer">
            <input class="cs-input small" :class="{'non-empty': linkToken.length > 0}" v-model="linkToken" placeholder="Enter token" />
            <button class="cs-button small" :disabled="!linkToken.length" @click="verifyTelegramToken">Submit</button>
        </div>
    </span>
    </div>
</template>
<script>
import axios from "axios"

export default {
    name: 'TelegramSection',
    emits: ['refresh'],
    props: {
        organization: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            linkToken: "",
        }
    },
    methods: {
        async verifyTelegramToken() {
            try {
                await axios.post("/organization/link-telegram", { token: this.linkToken })
                this.$root.displaySuccess("Telegram successfully linked")
                this.$emit('refresh')
            } catch (error) {
                this.$root.displayError(error.response.data.detail);
            }
        },
    }
}
</script>
<style scoped>
.cs-footer{
    display: flex;
    gap: 5px;
}
</style>